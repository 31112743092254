<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs
        :tabBarStyle="{
                    margin: '0',
                  }"
        v-model="active"
      >
        <a-tab-pane key="tab1" tab="领用记录"></a-tab-pane>
        <a-tab-pane key="tab2" tab="入库记录"></a-tab-pane>
      </a-tabs>
    </Pane>
    <a-card v-show="active === 'tab1'" class="container">
      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-range-picker v-model="form.date" />
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>
          <a-col :span="2">
            <div class="right" style="margin-top: 4px">
              <a-button @click="download" type="primary">导出</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :columns="[
          {
            key: 'deptName',
            title: '部门',
            scopedSlots: { customRender: 'deptName' },
          },
          ...titleList,
        ]"
        :dataSource="list"
        :loading="loading"
        :pagination="false"
      >
        <router-link
          slot="deptName"
          slot-scope="text, record"
          :to="setPath(record)"
          >{{ record.deptName }}</router-link
        >
      </a-table>
    </a-card>
    <a-card v-show="active === 'tab2'" class="container">
      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-select
                    style="width: 200px;margin-right: 20px;"
                    v-model="form.deptId"
                    show-search
                    :filter-option="$selectFilterOption"
                    placeholder="经办部门"
                >
                    <a-select-option
                    v-for="item in organizationList"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.uniqueName }}</a-select-option
                    >
                </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-range-picker v-model="form.date" />
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="queryB" type="primary">查询</a-button>
                <a-button @click="resetB">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />
      <a-table :indentSize="0" bordered :data-source="listB" @change="onChange" :pagination="{
                          total,
                          current,
                          pageSize,
                          showTotal: (total) => `共 ${total} 条记录`,
                        }" 
                        rowKey='id'
               >
        <a-table-column title="创建时间" data-index="createAt" width="120px"></a-table-column>
        <a-table-column title="经办人" data-index="applicantName"> </a-table-column>
        <a-table-column title="经办部门" data-index="deptName"> </a-table-column>
        <a-table-column title="总价" data-index="totalPrice"> </a-table-column>
        <a-table-column title="备注" data-index="remarks"> </a-table-column>
        <a-table-column title="操作" align="right" width="180px">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                @click="$router.push('/oa/material/index/addStorage?id=' + text.id)"
              >详情</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
      
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchApplyList, fetchWareList } from "@/api/oa/material";
import { downloadByHtml } from "@/utils/xlsx";
import organization from "@/mixins/organization";
import moment from "moment";
export default {
  name: "materialList",
  mixins: [watermark,organization],

  data() {
    return {
      active: "tab1",
      form: {},
      loading: false,
      titleList: [],
      list: [],
      current: 1,
      pageSize: 10,
      listB: [],
      total: 0,
    };
  },

  mounted() {
    // 本月第一天和本月最后一天
    const year = moment().year();
    const month = moment().month();

    this.form = {
      date: [
        moment([year, month, 1]),
        moment([year, month + 1, 1]).subtract(1, "days"),
      ],
    };

    this.getList();
    this.getListB();
  },

  methods: {
    getList() {
      const { date } = this.form;

      let startTime, endTime;
      if (Array.isArray(date) && date.length === 2) {
        startTime = date[0].format("YYYY-MM-DD");
        endTime = date[1].format("YYYY-MM-DD");
      }

      this.loading = true;
      fetchApplyList({
        startTime,
        endTime,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.titleList) && Array.isArray(res.dataList)) {
            this.titleList = res.titleList.map((item) => ({
              title: item,
              children: [
                {
                  title: "数量",
                  dataIndex: item + "数量",
                  align: "center",
                },
                {
                  title: "金额(元)",
                  dataIndex: item + "金额",
                  align: "center",
                },
              ],
            }));

            this.list = res.dataList
              .filter((item) => Array.isArray(item.dataList))
              .map((item) => {
                const obj = {};
                for (let i = 0; i < res.titleList.length; i++) {
                  const title = res.titleList[i];
                  const values = item.dataList[i];
                  obj[title + "数量"] = values.quantity;
                  obj[title + "金额"] = values.totalPrice;
                }

                return {
                  deptName: item.deptName,
                  deptId: item.deptId,
                  ...obj,
                };
              });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getListB();
    },
    getListB() {
      const { date,deptId } = this.form;
      let startDate, endDate;
      if (Array.isArray(date) && date.length === 2) {
        startDate = date[0].format("YYYY-MM-DD");
        endDate = date[1].format("YYYY-MM-DD");
      }
      fetchWareList({
        deptId: deptId,
        pageNum: this.current,
        pageSize: this.pageSize,
        startDate,
        endDate,
      })
        .then((res) => {
          this.loading = false;
          this.listB = res.list;
          this.total = res.totalSize ? res.totalSize : 0;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    queryB() {
      this.current = 1;
      this.getListB();
    },
    resetB() {
      this.form = {};
      this.getListB();
    },

    query() {
      this.getList();
    },
    reset() {
      this.form = {};
      this.getList();
    },

    download() {
      downloadByHtml(document.getElementsByTagName("table")[0], "物料申领台账");
    },

    setPath(record) {
      let url = this.$route.path + "/detail?deptId=" + record.deptId;

      const { date } = this.form;
      let startTime, endTime;
      if (Array.isArray(date) && date.length === 2) {
        startTime = date[0].format("YYYY-MM-DD");
        endTime = date[1].format("YYYY-MM-DD");
        url = `${url}&startTime=${startTime}&endTime=${endTime}`;
      }
      return url;
    },
  },
};
</script>

